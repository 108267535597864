// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// react-router
import { Link } from 'react-router-dom'

// semantic-ui
import { Button } from 'semantic-ui-react'

import apis from '../assets/image/apis.png'
import customerServer from '../assets/image/customerServer.png'

export const HomePage = observer(() => (
  <>
    <div style={{ backgroundColor: '#EEEEEE' }}>
      <div className='home'>
        <div className='container'>
          <div className='col-md-10 col-md-offset-1'>
            <h2 className='home-title' style={{ marginTop: '30px' }}>Welcome to MioConnect Developer</h2>
            <div className='row'>
              <div className='col-md-6'>
                <div className='card'>
                  <div className='image'>
                    <img src={ apis }/>
                  </div>
                  <div className='text'>
                    <h2>APIs</h2>
                    <div className='description'>
                      <span>
                        Explore our API documents to integrate MioConnect.Our document covers tthe essential: <br/>
                        Device Management - Query or operate devices in your account,like setting parameters,activate/deactivate,<br/>
                        Data Forwarding and Integration - Configure how data is being sent, in 3 different methods, and integrate MioConnect with your services.
                      </span>
                    </div>
                    <br/>
                    <Link to='/apis' data-testid='gettingStartedLink'>
                      <Button className='api-btn'>READ THE DOCS</Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='card'>
                  <div className='image'>
                    <img src={ customerServer }/>
                  </div>
                  <div className='text'>
                    <h2>Customer Server</h2>
                    <div className='description'>
                      <span>
                        Build with sample code designed to help you get started with your MioConnect integration.Learn how to build your own endpoint to receive data from MioConnect.
                      </span>
                    </div>
                    <br/>
                    <Link to='/customer' data-testid='gettingStartedLink'>
                      <Button className='api-btn'>MORE</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
))

export default HomePage
